import * as React from 'react'
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link, useParams } from 'react-router-dom'
import * as z from 'zod'
import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	css,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	IconButton,
	List,
	ListItem,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useLoggedInUserContext } from '../EnsureLoggedInContainer'
import { ILocale } from '../interfaces'
import Layout from '../Layout'
import toast from '../toast'
import config from '../config'
import httpUtils from '../utils/httpUtils'

type SelectType = {
	id: number
	label: string
}

enum SearchLimitation {
	All,
	Branches,
	Suburbs,
}

type SiteConfigType = {
	siteId: string
	searchLimitation: SearchLimitation
	branchIds: Array<number>
	suburbIds: Array<number>
	limitSearchTags: boolean
	searchTag: string
	limitSelectTags: boolean
	selectTags: Array<string>
}

type SiteConfigForm = {
	siteId: string
	searchLimitation: SearchLimitation
	branchIds: Array<number>
	suburbIds: Array<number>
	limitSearchTags: boolean
	searchTag: string
	limitSelectTags: boolean
	selectTags: Array<{ tag: string }>
}

const SiteEdit = () => {
	const params = useParams<{ siteId: string }>()
	const userContext = useLoggedInUserContext()
	const sites = userContext.getUserSites(userContext.user)
	const site = sites.find(x => x.siteId === params.siteId)

	const [loaded, setLoaded] = React.useState(false)

	const [siteConfig, setSiteConfig] = React.useState<SiteConfigType | null>(null)

	const [regions, setRegions] = React.useState<Array<ILocale>>([])
	const [districts, setDistricts] = React.useState<Array<ILocale>>([])
	const [suburbs, setSuburbs] = React.useState<Array<ILocale>>([])
	const [branches, setBranches] = React.useState<Array<SelectType>>([])
	const [searchLimitation, setSearchLimitation] = React.useState(SearchLimitation.All)

	const [selectedSuburbs, setSelectedSuburbs] = React.useState<Array<SelectType>>([])

	const [regionFilter, setRegionFilter] = React.useState<ILocale | null>(null)
	const [districtFilter, setDistrictFilter] = React.useState<ILocale | null>(null)

	const [searchTag, setSearchTag] = React.useState('')
	const handleSearchTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTag(event.target.value)
	}

	const [selectableTag, setSelectableTag] = React.useState('')
	const handleSelectableTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectableTag(event.target.value)
	}

	const isGuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

	const schema = z
		.object({
			siteId: z.string().min(1).regex(isGuid),
			searchLimitation: z.number(),
			branchIds: z.array(z.number()),
			suburbIds: z.array(z.number()),
			limitSearchTags: z.boolean(),
			searchTag: z.string().nullable(),
			limitSelectTags: z.boolean(),
			selectTags: z.array(z.object({ tag: z.string().min(1) })),
		})
		.superRefine((val, ctx) => {
			if (val.searchLimitation === SearchLimitation.Branches && (val.branchIds ?? []).length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['branchIds'],
					message: "If you select a 'Search Limit for Site' of 'Branches' you must select at least 1 branch.",
				})
			}

			if (val.searchLimitation === SearchLimitation.Suburbs && (val.suburbIds ?? []).length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['suburbIds'],
					message: "If you select a 'Search Limit for Site' of 'Suburbs' you must select at least 1 suburb.",
				})
			}

			if (val.limitSearchTags && (val.searchTag ?? '').length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['searchTag'],
					message: "You must supply a tag if 'Limit the Search tags for content' is selected.",
				})
			}

			if (val.limitSelectTags && (val.selectTags ?? []).length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['selectTags'],
					message: "You must supply tags if 'Limit the Selectable tags for content' is selected.",
				})
			}
		})

	const {
		register,
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm<SiteConfigForm>({
		resolver: zodResolver(schema),
		defaultValues: {
			branchIds: [],
			limitSearchTags: false,
			limitSelectTags: false,
			searchLimitation: SearchLimitation.All,
			searchTag: '',
			selectTags: [],
		},
	})
	const selectTags = useFieldArray({
		name: 'selectTags',
		control,
	})

	React.useEffect(() => {
		httpUtils.getExternal<SiteConfigType>(`${config.apiConfigUrl}/site-config/${site?.siteId}`).then(response => {
			if (response.ok) {
				setSiteConfig(response.data)
				setSearchLimitation(response.data.searchLimitation)
				reset({
					...response.data,
					selectTags: response.data.selectTags.map(x => ({ tag: x })),
				})
			} else toast.error('Something went wrong getting the site config.')
		})
		httpUtils.getExternal<Array<{ value: number; label: string }>>(`${import.meta.env.VITE_BAYLEYS_API}/api/branch/branch-lookups`).then(response => {
			if (response.ok) setBranches(response.data.map(x => ({ id: x.value, label: x.label })))
			else toast.error('Something went wrong getting the branches.')
		})
		httpUtils.getExternal<Array<ILocale>>(`${import.meta.env.VITE_BAYLEYS_API}/api/locale/regions`).then(response => {
			if (response.ok) setRegions(response.data)
			else toast.error('Something went wrong getting the regions.')
		})
		httpUtils.getExternal<Array<ILocale>>(`${import.meta.env.VITE_BAYLEYS_API}/api/locale/districts`).then(response => {
			if (response.ok) setDistricts(response.data)
			else toast.error('Something went wrong getting the districts.')
		})
		httpUtils.getExternal<Array<ILocale>>(`${import.meta.env.VITE_BAYLEYS_API}/api/locale/suburbs`).then(response => {
			if (response.ok) setSuburbs(response.data)
			else toast.error('Something went wrong getting the suburbs.')
		})
	}, [])

	React.useEffect(() => {
		if (siteConfig !== null && regions.length > 0 && districts.length > 0 && suburbs.length > 0) {
			setLoaded(true)
			const selectedSuburbs = suburbs.map(s => {
				const suburb: SelectType = {
					id: s.id,
					label: s.name,
				}
				if (s.parentId !== null) {
					const district = districts.find(x => x.id === s.parentId)
					if (district !== undefined) {
						const region = regions.find(x => x.id === district.parentId)
						if (region !== undefined) suburb.label = `${region.name} | ${district.name} | ${s.name}`
					}
				}

				return suburb
			})

			setSelectedSuburbs(
				selectedSuburbs.sort((x, y) => {
					if (x.label < y.label) return -1
					if (x.label > y.label) return 1
					return 0
				})
			)
		}
	}, [siteConfig, regions, districts, suburbs])

	if (site === undefined)
		return (
			<Layout title={`Site Edit Error`}>
				<Typography>You haven't supplied a siteId.</Typography>
			</Layout>
		)

	const filterDistrictOptions = (options: Array<ILocale>) => {
		let result = options.slice(0)
		if (regionFilter !== null) {
			result = result.filter(x => x.parentId === regionFilter.id)
		}
		return result
	}

	const filterSuburbOptions = (options: Array<SelectType>) => {
		let result = options.slice(0)
		if (regionFilter !== null && districtFilter === null) {
			result = result.filter(x => x.label.startsWith(`${regionFilter.name} | `))
		} else if (regionFilter !== null && districtFilter !== null) {
			result = result.filter(x => x.label.startsWith(`${regionFilter.name} | ${districtFilter.name} | `))
		}
		return result
	}

	const onSubmit = handleSubmit(data => {
		const siteConfig = {
			...data,
			selectTags: data.selectTags.map(x => x.tag),
		}
		console.log(`${config.apiUrl}/site-config`, data, siteConfig)
		httpUtils
			.postExternal(`${config.apiUrl}/site-config`, siteConfig)
			.then(response => {
				if (response.ok) toast.success(`Save ${site.name}'s config`)
				else toast.error(`Failed to save ${site.name}'s config`)
			})
			.catch((error: Error) => {
				const message = `Failed to save ${site.name}'s config`
				toast.error(message)
				console.error(message, error)
			})
	})

	const Error = (props: React.PropsWithChildren<unknown>) => {
		return <p style={{ color: 'red' }}>{props.children}</p>
	}

	return (
		<Layout title={`Site Edit ${site !== undefined && site.name}`}>
			{loaded && site === undefined && <Typography>The siteId '{params.siteId}' doesn't exist.</Typography>}
			{loaded && siteConfig !== null && site !== undefined && (
				<form onSubmit={onSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={4}>
							<FormControl>
								<FormLabel id='demo-radio-buttons-group-label'>Search Limit for Site</FormLabel>
								<Controller
									name='searchLimitation'
									control={control}
									render={({ field }) => (
										<RadioGroup
											defaultValue={SearchLimitation.All}
											row
											value={field.value}
											onChange={e => {
												field.onChange(e)
												setSearchLimitation(parseInt(e.target.value))
											}}
										>
											<FormControlLabel value={SearchLimitation.All} control={<Radio />} label='All' />
											<FormControlLabel value={SearchLimitation.Branches} control={<Radio />} label='Branches' />
											<FormControlLabel value={SearchLimitation.Suburbs} control={<Radio />} label='Suburbs' />
										</RadioGroup>
									)}
								/>
							</FormControl>
						</Grid>
					</Grid>
					{searchLimitation === SearchLimitation.Branches && (
						<Grid sx={{ marginTop: 5 }} container spacing={2}>
							<Grid item xs={4}>
								<Controller
									name='branchIds'
									control={control}
									render={({ field }) => {
										const value = branches.filter(x => field.value.findIndex(y => y === x.id) >= 0)
										return (
											<Autocomplete
												sx={{ width: '100%' }}
												id='branchIds'
												multiple
												disableCloseOnSelect
												options={branches}
												getOptionLabel={option => option.label}
												renderOption={(props, option) => (
													<li {...props} key={option.id}>
														{option.label}
													</li>
												)}
												value={value}
												onChange={(_e, v) => {
													field.onChange({
														target: {
															value: v.map(x => x.id),
														},
													})
												}}
												renderInput={params => <TextField {...params} variant='standard' label='Select the Branches this site uses' />}
											/>
										)
									}}
								/>
								<Error>{errors.branchIds?.message}</Error>
							</Grid>
						</Grid>
					)}
					{searchLimitation === SearchLimitation.Suburbs && (
						<>
							<Grid sx={{ marginTop: 5 }} container spacing={2}>
								<Grid item xs={4}>
									<Autocomplete
										sx={{ width: '100%' }}
										id='regions'
										options={regions}
										getOptionLabel={option => option.name}
										renderOption={(props, option) => (
											<li {...props} key={option.id}>
												{option.name}
											</li>
										)}
										onChange={(_e, v) => {
											setRegionFilter(v)
										}}
										renderInput={params => <TextField {...params} variant='standard' label='Select the Region to filter' />}
									/>
								</Grid>
								<Grid item xs={4}>
									<Autocomplete
										sx={{ width: '100%' }}
										id='districts'
										options={filterDistrictOptions(districts)}
										getOptionLabel={option => option.name}
										renderOption={(props, option) => (
											<li {...props} key={option.id}>
												{option.name}
											</li>
										)}
										onChange={(_e, v) => {
											setDistrictFilter(v)
										}}
										renderInput={params => <TextField {...params} variant='standard' label='Select the District to filter' />}
									/>
								</Grid>
							</Grid>
							<input type='hidden' value={site.siteId} {...register('siteId')} />
							<Grid container spacing={2}>
								<Grid sx={{ marginTop: 5 }} item xs={12}>
									<Typography sx={{ marginBottom: 1 }}>Leave empty for all suburbs.</Typography>
									<Controller
										name='suburbIds'
										control={control}
										render={({ field }) => (
											<Autocomplete
												sx={{ width: '100%' }}
												multiple
												options={filterSuburbOptions(selectedSuburbs)}
												disableCloseOnSelect
												getOptionLabel={option => option.label}
												renderOption={(props, option) => (
													<li {...props} key={option.id}>
														{option.label}
													</li>
												)}
												value={
													field === undefined || field.value === undefined ? [] : (field.value as Array<number>).map(x => selectedSuburbs.find(y => y.id == x)!)
												}
												onChange={(e, v) => {
													field.onChange(v.map(x => x.id))
												}}
												isOptionEqualToValue={(o, v) => {
													return o.id === v.id
												}}
												renderInput={params => <TextField {...params} variant='standard' label='Select the Suburbs for the Site' />}
											/>
										)}
									/>
									<Error>{errors.suburbIds?.message}</Error>
								</Grid>
							</Grid>
						</>
					)}
					<Grid container spacing={2}>
						<Grid sx={{ marginTop: 5, marginBottom: 2 }} item xs={12}>
							<Typography sx={{ marginBottom: 2 }}>
								The following section allows you to filter the tags that can be selected for content, e.g. News Article List. It is in 2 sections, the first is
								a setting to make all tag lookups be prefiltered. This allows content for sites, e.g. canterbury, to be filtered without user input. The second
								is a list of tags available to the user for selection in the CMS. Both are a free text list you can supply with no validation against existing
								tags, this is is for flexibility.
							</Typography>
							<Typography sx={{ marginBottom: 1 }}>
								An example for how to use this would be used for a News Article List is to first limit the search tag by entering Canterbury, which will first
								limit all content tagged with Canterbury for the control in the CMS. Then set the limit the selectable tags to residential, rural, commercial.
								If this is applied to a News Article in the canterbury site, then all News Article List controls will be constrained to Canterbury, then the
								user in the CMS can select residential, rural or commercial in the selector.
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								name='limitSearchTags'
								control={control}
								render={({ field }) => (
									<>
										<FormGroup>
											<FormControlLabel
												control={<Checkbox value={field.value} checked={field.value} onChange={field.onChange} />}
												label='Limit the Search tags for content'
											/>
										</FormGroup>
										{field.value && (
											<>
												<Controller
													name='searchTag'
													control={control}
													render={({ field }) => (
														<TextField
															id='searchTag'
															label='Enter the Predefined Search Name'
															variant='outlined'
															value={field.value ?? ''}
															onChange={field.onChange}
														/>
													)}
												/>
												<Error>{errors.searchTag?.message}</Error>
											</>
										)}
									</>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								name='limitSelectTags'
								control={control}
								render={({ field }) => (
									<>
										<FormGroup>
											<FormControlLabel
												control={<Checkbox value={field.value} checked={field.value} onChange={field.onChange} />}
												label='Limit the Selectable tags for content'
											/>
										</FormGroup>
										{field.value && (
											<>
												<TextField id='tagName' label='Enter the Tag Name' variant='outlined' value={selectableTag} onChange={handleSelectableTagChange} />
												<Button
													disabled={selectableTag.length === 0 || selectTags.fields.findIndex(x => x.tag === selectableTag) >= 0}
													onClick={() => {
														selectTags.append({ tag: selectableTag })
														setSelectableTag('')
													}}
													variant='contained'
													color='secondary'
													sx={{ height: 55, marginLeft: 1 }}
												>
													Add Tag
												</Button>
												<List sx={{ maxWidth: 300 }}>
													{selectTags.fields.map((tag, index) => (
														<ListItem
															key={tag.id}
															secondaryAction={
																<IconButton edge='end' aria-label='delete' onClick={() => selectTags.remove(index)}>
																	<DeleteIcon />
																</IconButton>
															}
														>
															{selectTags.fields[index].tag}
														</ListItem>
													))}
												</List>
												<Error>{errors.selectTags?.message}</Error>
											</>
										)}
									</>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Box display='flex' justifyContent='flex-end'>
								<Button type='submit'>Submit</Button>
								<Link css={css({ textDecoration: 'none' })} to='/'>
									<Button sx={{ marginLeft: 1 }}>Cancel</Button>
								</Link>
							</Box>
						</Grid>
					</Grid>
				</form>
			)}
		</Layout>
	)
}

export default SiteEdit
