import React, { useEffect } from 'react'
import { Button, css, Dialog, DialogActions, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useLoggedInUserContext } from '../EnsureLoggedInContainer'
import Layout from '../Layout'
import httpUtils from '../utils/httpUtils'
import config from '../config'


type FastlyService = { serviceId: string, name: string }

export interface ISiteTinyDto {
  siteId: string
  parentSiteId: string | null
  parentSiteName: string | null
  name: string
  assetsSiteId: string | null
  dynamicsSiteId: string | null
}

const purgeService = async (
  service: FastlyService | undefined,
  hard: boolean,
  setPurge: (val: { service: FastlyService, hard: boolean } | undefined) => void) => {
  if (service !== undefined) {
    await httpUtils.getExternal(`${config.apiUrl}/purge-fastly-cache${(hard ? '-hard' : '')}/${service.serviceId}`)
      .then((r) => {
        if (r.ok) {
          setPurge(undefined)
        } else {
          alert('Something went wrong purging the fastly service.')
        }
      })
  }
}

function Sites() {
  const userContext = useLoggedInUserContext();
  const sites = userContext.getUserSites(userContext.user)
  const navigate = useNavigate()

  const [fastlyServices, setFastlyServices] = React.useState<Array<FastlyService>>([])
  const [purge, setPurge] = React.useState<{ service: FastlyService, hard: boolean } | undefined>()

  useEffect(() => {
    httpUtils.getExternal<Array<FastlyService>>(`${config.apiConfigUrl}/fastly-services`)
      .then((r) => {
        if (r.ok) {
          setFastlyServices(r.data)
        }
      })
  }, [])

  return (
    <Layout title='Sites'>
      {sites.length === 0 &&
        <Typography>There are no sites for you to configure</Typography>}
      {sites.length > 0 &&
        <>
          <Typography>Click edit on a site below to edit the criteria for interacting with the api.bayleys.co.nz.</Typography>
          <TableContainer sx={{ marginTop: 2 }} component={Paper}>
              <Table sx={{ width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Site Name</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sites.map(site => (
                    <TableRow key={site.siteId}>
                      <TableCell align="left">{site.name}</TableCell>
                      <TableCell align="right"><Button onClick={() => navigate(`/${site.siteId}`)}>Edit</Button></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>}
      {fastlyServices.length > 0 &&
        <>
          <br/>
          <br/>
          <br/>
          <Dialog
            open={purge !== undefined}
            onClose={() => setPurge(undefined)}>
            <DialogTitle>
              Are you sure you want to purge {purge?.service.name}?
            </DialogTitle>
            <DialogActions>
              <Button onClick={async () => await purgeService(purge?.service, purge?.hard ?? false, setPurge)}>Ok</Button>
              <Button onClick={() => setPurge(undefined)}>Cancel</Button>
            </DialogActions>
          </Dialog>
          <Typography>Click purge against a service below to clear the caching in fastly.</Typography>
          <TableContainer sx={{ marginTop: 2 }} component={Paper}>
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Service Name</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fastlyServices.map(service => (
                  <TableRow key={service.serviceId}>
                    <TableCell align="left">{service.name}</TableCell>
                    <TableCell align="right"><Button onClick={() => setPurge({ service, hard: false })}>Purge</Button></TableCell>
                  </TableRow>
                ))}
                {fastlyServices.map(service => (
                  <TableRow key={service.serviceId}>
                    <TableCell align="left">{service.name}</TableCell>
                    <TableCell align="right"><Button onClick={() => setPurge({ service, hard: true })}>Purge Hard</Button></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>}
    </Layout>
  );
}

export default Sites;