import { useEffect, useState, createRef, useRef } from 'react'
// import { useForm } from 'react-hook-form'
import { ArragroCmsCustomControl } from '@arragro/arragrocms-customcontrol'
import { ControlContext } from '@arragro/arragrocms-customcontrol/types/global'
import httpUtils from '../utils/httpUtils'
import toast from '../toast'
import { Autocomplete, FormControl, FormHelperText, Grid, TextField } from '@mui/material'
import { isArray } from 'lodash-es'

// type TagSelectorForm = {
//     tags: Array<string>
// }

export interface LookupValues {
	value: number
	label: string
}

const BranchSelector = () => {
	const containerRef = createRef<HTMLDivElement>()
	const [arragroCmsCustomControl, setArragroCmsCustomControl] = useState<ArragroCmsCustomControl | undefined>()
	const [controlContext, setControlContext] = useState<ControlContext | undefined>()
	const [branches, setBranches] = useState<Array<LookupValues>>([])
	const [selectedBranches, setSelectedBranches] = useState<Array<number>>([])
	const [containerBaseHeight, setContainerBaseHeight] = useState(0)
	const [containerHeight, setContainerHeight] = useState(0)
	const [multiSelect, setMultiSelect] = useState(true)
	const [includeBranchName, setIncludeBranchName] = useState(false)
	const [error, setError] = useState(false)
	const [isTouched, setIsTouched] = useState(false)
	const [formSubmitted, setFormSubmitted] = useState(false)

	// const { register, handleSubmit, control, reset, formState: { errors } } = useForm<TagSelectorForm>({
	//     defaultValues: {
	//         tags: []
	//     }
	// });

	const onInit = (controlContext: ControlContext) => {
		console.log('BranchSelector:Init', controlContext)
		setControlContext(controlContext)
		const data = controlContext.value

		let derivedMultiSelect = true
		if (controlContext.config !== undefined) {
			derivedMultiSelect = controlContext.config.multiSelect ?? true
			setMultiSelect(derivedMultiSelect)
			setIncludeBranchName(controlContext.config.includeBranchName ?? false)
		}

		if (derivedMultiSelect) {
			setSelectedBranches(
				(data.branches ?? []).map((x: string | { branchId: string; branchName: string }) => {
					if (x instanceof Object) {
						return x.branchId
					} else {
						return x
					}
				})
			)
		} else {
			if (data.branch instanceof Object) {
				setSelectedBranches(data.branch === undefined || data.branch === null ? [] : [data.branch.branchId])
			} else {
				setSelectedBranches(data.branch === undefined || data.branch === null ? [] : [data.branch])
			}
		}
	}

	const onResetData = (controlContext: ControlContext) => {
		setControlContext(controlContext)
		const data = controlContext.value
		if (multiSelect) {
			setSelectedBranches(
				data.agents.map((x: string | { branchId: string; branchName: string }) => {
					if (x instanceof Object) {
						return x.branchId
					} else {
						return x
					}
				})
			)
		} else {
			if (data.branch instanceof Object) {
				setSelectedBranches(data.branch === undefined || data.branch === null ? [] : [data.branch.branchId])
			} else {
				setSelectedBranches(data.branch === undefined || data.branch === null ? [] : [data.branch])
			}
		}
	}

	const onFormSubmit = (controlContext: ControlContext) => {
		console.log('Branch:onFormSubmit', controlContext)
		setFormSubmitted(true)
	}

	useEffect(() => {
		const arragroCmsCustomControl = new ArragroCmsCustomControl(window.name, {
			onInit: controlContext => onInit(controlContext),
			onResetData: controlContext => onResetData(controlContext),
			onFormSubmit: content => onFormSubmit(content),
		})
		setArragroCmsCustomControl(arragroCmsCustomControl)
		arragroCmsCustomControl.Init()
	}, [])

	useEffect(() => {
		if (controlContext !== undefined) {
			httpUtils.getExternal<Array<LookupValues>>('https://cms-api.bayleys.co.nz/api/branches').then(response => {
				if (response.ok) {
					setBranches(response.data)
				} else toast.error('Something went wrong getting branches.')
			})
		}
	}, [controlContext])

	useEffect(() => {
		if (arragroCmsCustomControl) {
			if (selectedBranches.length === 0 && !multiSelect) {
				arragroCmsCustomControl.OnValidChange(false)
			}
		}
	}, [arragroCmsCustomControl, selectedBranches])

	useEffect(() => {
		// if (agents !== undefined) {
		//     arragroCmsCustomControl?.SetHeight((agents.length > 4 ? 180 : 50) + (agents.length > 4 ? 4 * 42 : agents.length * 40))
		// }
		if (containerRef.current !== null && containerBaseHeight === 0) {
			console.log('BranchSelector:Height', containerRef.current.offsetHeight)
			setContainerBaseHeight(containerRef.current.offsetHeight)
			setContainerHeight(containerRef.current.offsetHeight)
		}
	}, [containerRef])

	useEffect(() => {
		arragroCmsCustomControl?.SetHeight(containerHeight)
	}, [containerHeight])

	useEffect(() => {
		if (!multiSelect) {
			setError(selectedBranches.length === 0)
		}
	}, [formSubmitted])

	useEffect(() => {
		if (containerRef.current !== null) {
			setContainerBaseHeight(containerRef.current.offsetHeight)
			setContainerHeight(containerRef.current.offsetHeight)
		}
	}, [error])

	if (arragroCmsCustomControl === undefined || controlContext === undefined || branches === undefined) return null

	return (
		<Grid ref={containerRef} sx={{ width: '98%' }} container>
			<Grid item xs={12} sx={{ marginTop: 1 }}>
				<FormControl error={error} sx={{ width: '100%' }}>
					<Autocomplete
						disableCloseOnSelect={multiSelect}
						multiple={multiSelect}
						options={branches}
						renderOption={(props, option) => (
							<li {...props} key={option.value}>
								{option.label}
							</li>
						)}
						getOptionLabel={option => option.label}
						value={
							multiSelect
								? branches.filter(x => selectedBranches.findIndex(y => y === x.value) >= 0)
								: branches.find(x => selectedBranches.findIndex(y => y === x.value) >= 0) ?? null
						}
						onChange={(_e, value) => {
							setIsTouched(true)
							if (isArray(value)) {
								const selectedAgents = value !== null ? value : []
								const sortedSelectedAgents = selectedAgents.sort((a, b) => a.label.localeCompare(b.label))
								let newSelectedAgents = new Array<any>()
								if (includeBranchName) {
									newSelectedAgents = sortedSelectedAgents.map(x => ({ branchId: x.value, branchName: x.label }))
									setSelectedBranches(newSelectedAgents.map(x => x.branchId))
								} else {
									newSelectedAgents = sortedSelectedAgents.map(x => x.value)
									setSelectedBranches(newSelectedAgents)
								}
								arragroCmsCustomControl.OnChange(controlContext.name, { branches: newSelectedAgents })
							} else {
								const selectedBranches = value !== null ? [value] : []
								setSelectedBranches(selectedBranches.map(x => x.value))
								if (selectedBranches.length === 0) {
									arragroCmsCustomControl.OnChange(controlContext.name, {}, false)
								} else {
									if (!includeBranchName) {
										arragroCmsCustomControl.OnChange(
											controlContext.name,
											{ branch: selectedBranches.length > 0 ? selectedBranches.map(x => x.value)[0] : null },
											true
										)
									} else {
										arragroCmsCustomControl.OnChange(
											controlContext.name,
											{ branch: selectedBranches.length > 0 ? selectedBranches.map(x => ({ branchId: x.value, branchName: x.label }))[0] : null },
											true
										)
									}
								}
								setError(selectedBranches.length === 0)
							}
						}}
						sx={{
							'& ul': {
								height: '180px',
								maxHeight: '180px',
							},
						}}
						ListboxProps={{
							style: {
								height: '180px',
								maxHeight: '180px',
							},
						}}
						onOpen={() => {
							setContainerHeight(containerBaseHeight + 180)
						}}
						onClose={() => {
							if (containerRef.current) {
								setContainerBaseHeight(containerRef.current.offsetHeight)
								setContainerHeight(containerRef.current.offsetHeight)
							} else {
								setContainerHeight(containerBaseHeight)
							}
							console.log('BranchSelector:OnCloseHeight', containerRef.current?.offsetHeight)
						}}
						renderInput={params => <TextField {...params} label='Select Branches' error={error && (isTouched || formSubmitted)} />}
					/>
					{error && <FormHelperText id='component-error-text'>You must select a branch</FormHelperText>}
				</FormControl>
			</Grid>
		</Grid>
	)
}

export default BranchSelector
