import * as React from 'react'
import { Theme, useTheme } from '@mui/material'
import { css } from '@emotion/react'

export const errorColour = '#f44336'

const navigation = (theme: Theme) => css({
    boxSizing: 'border-box',
    position: 'relative',
    cursor: 'text',
    display: 'block',
    alignItems: 'center',
    borderRadius: '5px',
    border: `1px solid ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'}`,
    '&:hover': {
        border: `1px solid ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.85)' : 'rgba(255, 255, 255, 0.85)'}`,
    },
    '& > legend span': {
        fontSize: '0.75em',
        padding: theme.spacing(0, .61, 0, .61)
    }
})

const ComponentWrapper = (props: React.PropsWithChildren<{
    label?: string,
    hasError?: boolean
}>) => {
    const theme = useTheme()

    const {
        label,
        hasError,
        children,
        ...otherProps
    } = props

    return <fieldset aria-hidden="true" css={[
        navigation(theme), 
        hasError ? { borderColor: errorColour, '&:hover': { borderColor: errorColour } } : {}]}
        {...otherProps}
    >
        {label && <legend>
            <span css={hasError ? { color: errorColour } : {}}>{label}</span>
        </legend>}
        {children}
    </fieldset>
}

export default ComponentWrapper