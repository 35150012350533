import { getAdvancedSearchPostFromQuery, getSearchContext, SearchContextProvider } from '@bayleys-azure/advanced.search'
import { namespace } from '@bayleys-azure/advanced.search/dist/renderer/types'
import { PropsWithChildren, useEffect } from 'react'
import { useState } from 'react'
import config from '../config'
import httpUtils from '../utils/httpUtils'

export interface ISearchRefs {
	submitForm: () => namespace.AdvancedSearchPost
}

export interface ISearchProps {
	siteId: string
	queryString?: string
}

const SearchContextProviderWrapper = (props: PropsWithChildren<ISearchProps>) => {
	const [searchContext, setSearchContext] = useState<namespace.SearchContext | null | undefined>(null)
	const [host, setHost] = useState<string | null>(null)
	const [loadedHost, setLoadedHost] = useState(false)

	const { siteId, queryString, children } = props

	useEffect(() => {
		httpUtils.getExternal<{ host: string; port: number } | null>(`${config.apiConfigUrl}/site-alias/${siteId}`).then(response => {
			if (response.ok) {
				if (response.data !== null) {
					const hostAndPort = `${response.data.host}:${response.data.port}`
					setHost(hostAndPort)
					getSearchContext(getAdvancedSearchPostFromQuery('Property', undefined, undefined, ''), hostAndPort)
						.then(sc => {
							setSearchContext(sc)
						})
						.catch(() => {
							setSearchContext(undefined)
						})
				}
				setLoadedHost(true)
			}
		})
	}, [])

	if (host === null) return null

	if (loadedHost && searchContext === undefined) return <h1>Something has gone wrong retriving the host, does the site have configured aliases in the CMS?</h1>

	if (searchContext === null || searchContext === undefined) return null

	return (
		<SearchContextProvider
			searchContext={searchContext}
			host={host}
			pathname={window.location.pathname}
			urlOriginal={`${window.location.pathname}${queryString ?? ''}`}
			viewType='Grid'
			tabType='Property'
			propertyType='Everything'
			getSearchPostFromQuery={getAdvancedSearchPostFromQuery}
			disableSessionStorage
			resolveRouteWrapper={() => ({
				match: false,
				routeParams: {},
			})}
		>
			{children}
		</SearchContextProvider>
	)
}

export default SearchContextProviderWrapper
