import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthProvider } from 'oidc-react'
import { SnackbarProvider } from 'notistack'
import { createTheme, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'

import Config, { UserManager } from './config'
import { SnackbarUtilsConfigurator } from './toast'
import EnsureLoggedInContainer from './EnsureLoggedInContainer'
import Sites from './sites/Index'
import AccessDenied from './AccessDenied'
import SiteEdit from './sites/Edit'
import TagSelector from './tagSelector/Index'
import AdvancedSearch from './advancedSearch/Index'
import AgentSelector from './agentSelector/Index'
import BranchSelector from './branchSelector/Index'

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ABABAB',
    },
    secondary: {
      main: '#00A98F',
      light: '#e6fffb',
      contrastText: '#FFF',
    },
  },
  shape: {
    borderRadius: 5,
  },
  components: {
      MuiButton: {
        defaultProps: {
            color: 'secondary',
            variant: 'contained'
        }
      },
      MuiPaper: {
        defaultProps: {
            square: false,
            elevation: 6,
        }
      },
      MuiAppBar: {
          styleOverrides: {
              colorInherit: {
                  backgroundColor: '#FFF'
              }
          }
      },
      MuiAutocomplete: {
        styleOverrides: {
            option: {
                '&[aria-selected="true"]': {
                    backgroundColor: '#999!important',
                    borderColor: 'transparent',
                },
            }
        }
      },
      MuiCheckbox: {
          defaultProps: {
              color: 'secondary',
              disableRipple: true
          }
      },
      MuiChip: {
          defaultProps: {
              variant: 'outlined'
          }
      },
      MuiTextField: {
          defaultProps: {
              variant: 'outlined'
          }
      },
      MuiRadio: {
          defaultProps: {
              color: 'secondary'
          }
      } 
  }
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <SnackbarProvider
                maxSnack={5}
                preventDuplicate
                autoHideDuration={3000}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
            >
                <>
                    <SnackbarUtilsConfigurator />
                        <BrowserRouter>
                            <Routes>
                                <Route path='/site-tags' element={<TagSelector />} />
                                <Route path='/advanced-search' element={<AdvancedSearch />} />
                                <Route path='/agents' element={<AgentSelector />} />
                                <Route path='/branches' element={<BranchSelector />} />
                                <Route element={<EnsureLoggedInContainer />}>
                                    <Route path='/' element={<Sites />} />
                                    <Route path='/:siteId' element={<SiteEdit />} />
                                    <Route path='/access-denied' element={<AccessDenied />} />
                                </Route>
                            </Routes>
                        </BrowserRouter>
                </>
            </SnackbarProvider>
        </ThemeProvider>
    </StyledEngineProvider>
)
