import { useEffect, useState, createRef } from 'react'
import { ArragroCmsCustomControl } from '@arragro/arragrocms-customcontrol'
import { ControlContext } from '@arragro/arragrocms-customcontrol/types/global'
import { Grid } from '@mui/material'
import SearchContextProviderWrapper from './SearchContextProviderWrapper'
import { AdvancedSearchForm, getAdvancedSearchPostUrlData, serialize, theme } from '@bayleys-azure/advanced.search'
import { ISearchFormRefs } from '@bayleys-azure/advanced.search/dist/components/search/filter/SearchForm'
import { namespace } from '@bayleys-azure/advanced.search/dist/renderer/types'


const AdvancedSearch = () => {
    const containerRef = createRef<HTMLDivElement>()
    const [arragroCmsCustomControl, setArragroCmsCustomControl] = useState<ArragroCmsCustomControl | undefined>()
    const [controlContext, setControlContext] = useState<ControlContext | undefined>()
    const [siteId, setSiteId] = useState<string>()
    const [packagesOnly, setPackagesOnly] = useState(false)
    const [multipleSearchTypes, setMultipleSearchTypes] = useState(false)
    const [queryString, setQueryString] = useState<string>()

    const onWatch = (data: namespace.SearchPost) => {
        if (arragroCmsCustomControl !== undefined &&
            controlContext !== undefined) {
            const searchPost = getAdvancedSearchPostUrlData(data as namespace.AdvancedSearchPost)
            arragroCmsCustomControl.OnChange(controlContext.name, { queryString: `?${serialize(searchPost)}` })
        }
    }

    const formRef = createRef<ISearchFormRefs>()

    const onInit = (controlContext: ControlContext) => {
        console.log('AdvancedSearch:Init', controlContext)
        setControlContext(controlContext)
        setQueryString(controlContext.value.queryString || '')

        if (controlContext.config !== undefined) {
            setPackagesOnly(controlContext.config.packagesOnly ?? false)
            setMultipleSearchTypes(controlContext.config.multipleSearchTypes ?? false)
        }
    }

    const onResetData = (controlContext: ControlContext) => {
        setControlContext(controlContext)
        setQueryString(controlContext.value.queryString || '')
    }

    useEffect(() => {
        const arragroCmsCustomControl = new ArragroCmsCustomControl(window.name, {
            onInit,
            onResetData
        })
        setArragroCmsCustomControl(arragroCmsCustomControl)
        arragroCmsCustomControl.Init()
    }, [])

    useEffect(() => {
        if (controlContext !== undefined) {
            const siteId = controlContext.siteId
            setSiteId(siteId)            
        }
    }, [controlContext])

    useEffect(() => {
        if (queryString !== undefined) {
            if (packagesOnly) {
                arragroCmsCustomControl?.SetHeight(300)
            } else {
                arragroCmsCustomControl?.SetHeight(700)
            }
        }
    }, [containerRef])

    if (arragroCmsCustomControl === undefined || controlContext === undefined || queryString === undefined || siteId === undefined) return null
    
    return <Grid ref={containerRef} sx={{ width: '98%' }} container>
        <Grid item xs={12}>
            <SearchContextProviderWrapper
                siteId={siteId}
                queryString={queryString}
            >
                <AdvancedSearchForm
                    ref={formRef}
                    hidePeople
                    hideOffice
                    hideMedia
                    packagesOnly={packagesOnly}
                    multipleSearchTypes={multipleSearchTypes}
                    onWatch={onWatch}
                    onSubmit={(data) => {
                        return data
                    }}
                />
            </SearchContextProviderWrapper>
        </Grid>
    </Grid>
}

export default AdvancedSearch