import { initUserManager } from "oidc-react";

const getValue = (val: any, removeTrailingSlash: boolean, ensureTrailingSlash: boolean = false, posix: string = '', override: boolean = false) => {
    if (!override && import.meta.env.VITE_USE_LOCAL === 'true') {
        let url = window.location.origin
        if (posix.length > 0) {
            if (url.endsWith('/') && posix.startsWith('/')) {
                url = url.slice(0, -1) + posix
            } else if (!url.endsWith('/') && !posix.startsWith('/')) {
                url = url + '/' + posix
            }
        }

        if (url.endsWith('/') && removeTrailingSlash) {
            return url.slice(0, -1)
        } else if (ensureTrailingSlash && !url.endsWith('/')) {
            return url + '/'
        }
        return url
    }
    return val
}

const config = {
    apiUrl: getValue(import.meta.env.VITE_API_URL, true),
    apiConfigUrl: getValue(import.meta.env.VITE_API_CONFIG_API, true),
    oidcConfig: {
        onSignIn: () => {
            // Redirect?
            const redirect = sessionStorage.getItem('redirectOnLogin')
            if (redirect !== null) {
                let href = '/'
                if (redirect !== '/logged-out') {
                    href = redirect
                }
                window.history.replaceState({},
                    window.document.title,
                    window.location.origin + redirect);
                window.location.href = redirect
            }
            // window.history.pushState("", "", sessionStorage.getItem('redirectOnLogin'));
        },
        autoSignIn: import.meta.env.VITE_OIDC_AUTO_SIGN_IN === 'true',
        automaticSilentRenew: import.meta.env.VITE_OIDC_AUTOMATIC_SILENT_RENEW === 'true',
        authority: getValue(import.meta.env.VITE_OIDC_AUTHORITY, true, false, '', import.meta.env.VITE_FORCE_AUTHORITY === 'true'),
        clientId: import.meta.env.VITE_OIDC_CLIENT_ID,
        clientSecret: import.meta.env.VITE_OIDC_CLIENT_SECRET,
        redirectUri: getValue(import.meta.env.VITE_OIDC_REDIRECT_URI, false, true),
        popupRedirectUri: getValue(import.meta.env.VITE_OIDC_POPUP_REDIRECT_URI, false, true),
        postLogoutRedirectUri: getValue(import.meta.env.VITE_OIDC_POST_LOGOUT_REDIRECT_URI, false, false, 'logged-out'),
        response_type: import.meta.env.VITE_OIDC_RESPONSE_TYPE,
        loadUserInfo: import.meta.env.VITE_OIDC_LOAD_USER_INFO === 'true',
        scope: 'openid api email roles profile cms_manager'
    }
}

console.log(import.meta.env, config, config.oidcConfig)

export const UserManager = initUserManager(config.oidcConfig)

export default config