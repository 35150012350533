import { useEffect, useState, createRef } from 'react'
// import { useForm } from 'react-hook-form'
import { ArragroCmsCustomControl } from '@arragro/arragrocms-customcontrol'
import { ControlContext } from '@arragro/arragrocms-customcontrol/types/global'
import httpUtils from '../utils/httpUtils'
import config from '../config'
import toast from '../toast'
import { Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material'
import ComponentWrapper from '../ComponentWrapper'

// type TagSelectorForm = {
//     tags: Array<string>
// }

const TagSelector = () => {
	const containerRef = createRef<HTMLDivElement>()
	const [arragroCmsCustomControl, setArragroCmsCustomControl] = useState<ArragroCmsCustomControl | undefined>()
	const [controlContext, setControlContext] = useState<ControlContext | undefined>()
	const [tags, setTags] = useState<Array<string> | undefined>([])
	const [filteredTags, setFilteredTags] = useState<Array<string> | undefined>()
	const [selectedTags, setSelectedTags] = useState<Array<string>>([])
	const [tagOption, setTagOption] = useState(0)

	// const { register, handleSubmit, control, reset, formState: { errors } } = useForm<TagSelectorForm>({
	//     defaultValues: {
	//         tags: []
	//     }
	// });

	const onInit = (controlContext: ControlContext) => {
		console.log('TagSelector:Init', controlContext)
		setControlContext(controlContext)
		const data = controlContext.value
		const dataTags = data.tags || []
		setSelectedTags(dataTags)
		//setSelectedTag(controlContext.value.tag)
		if (dataTags.length > 0) setTagOption(1)
	}

	const onResetData = (controlContext: ControlContext) => {
		setControlContext(controlContext)
		const data = controlContext.value
		setSelectedTags(data.tags || [])
		//setSelectedTag(controlContext.value.tag)
	}

	useEffect(() => {
		const arragroCmsCustomControl = new ArragroCmsCustomControl(window.name, {
			onInit,
			onResetData,
		})
		setArragroCmsCustomControl(arragroCmsCustomControl)
		arragroCmsCustomControl.Init()
	}, [])

	useEffect(() => {
		if (controlContext !== undefined) {
			const siteId = controlContext.siteId
			httpUtils.getExternal<Array<string>>(`${config.apiConfigUrl}/site-tags/${siteId}`).then(response => {
				if (response.ok) {
					setTags(response.data)
					setFilteredTags(response.data)
				} else toast.error('Something went wrong getting the site tags.')
			})
		}
	}, [controlContext])

	const setConatinerHeight = () => {
		if (tags !== undefined) {
			if (containerRef.current !== null && tagOption === 1) {
				arragroCmsCustomControl?.SetHeight((tags.length > 4 ? 180 : 50) + (tags.length > 4 ? 4 * 42 : tags.length * 40))
			} else {
				arragroCmsCustomControl?.SetHeight(90)
			}
		}
	}

	useEffect(() => {
		setConatinerHeight()
	}, [containerRef])

	useEffect(() => {
		if (tagOption === 0) {
			setSelectedTags([])
		}
		setConatinerHeight()
	}, [tagOption])

	if (arragroCmsCustomControl === undefined || controlContext === undefined || tags === undefined || filteredTags === undefined) return null

	return (
		<Grid ref={containerRef} sx={{ width: '98%' }} container>
			<Grid item xs={12}>
				{/* <Autocomplete
                sx={{ width: '100%' }}
                id='tags'
                multiple
                open
                PaperComponent={CustomPaper}
                disablePortal
                options={tags.map(tag => ({ value: tag, label: tag }))}
                //disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <li {...props} key={option.value}>
                        {option.label}
                    </li>
                )}
                value={selectedTags.map(tag => ({ value: tag, label: tag }))}
                onChange={(event, value) => {
                    const values = value.map(option => option.value)
                    setSelectedTags(values)
                    arragroCmsCustomControl.OnChange('tags', values)
                }}
                isOptionEqualToValue={(o, v) => {
                    return o.value === v.value
                }}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    variant='standard'
                    label='Select the Tags'
                    />
                )}
            /> */}
				<ComponentWrapper label={controlContext.label}>
					<FormControl>
						<RadioGroup value={tagOption} row>
							<FormControlLabel
								control={<Radio />}
								label='All'
								value={0}
								onChange={() => {
									setTagOption(0)
									arragroCmsCustomControl.OnChange(controlContext.name, { tags: [] })
								}}
							/>
							<FormControlLabel
								control={<Radio />}
								label='Limited'
								value={1}
								onChange={() => {
									setTagOption(1)
									arragroCmsCustomControl.OnChange(controlContext.name, { tags: [] })
								}}
							/>
						</RadioGroup>
					</FormControl>
					{tagOption === 1 && (
						<>
							{tags.length > 4 && (
								<div>
									<TextField
										label='Filter tags'
										placeholder='Enter the tag name you want to filter'
										onChange={e => {
											const value = e.target.value
											if (value.length === 0) {
												setFilteredTags(tags)
											} else {
												setFilteredTags(tags.filter(x => x.toLowerCase().startsWith(value)))
											}
										}}
									/>
								</div>
							)}

							{/* <div style={{ maxHeight: (tags.length > 4 ? 200 : 160), overflow: 'hidden auto' }}>                        
                            <RadioGroup
                                value={selectedTag}
                                onChange={e => {
                                    const value = e.target.value
                                    setSelectedTag(value)
                                    arragroCmsCustomControl.OnChange('tag', [value])
                                }}>
                                {filteredTags.map((value, index) => (
                                    <FormControlLabel key={`tag-${index}`} value={value} control={<Radio />} label={value} />
                                ))}
                            </RadioGroup>
                        </div> */}
							<div style={{ maxHeight: tags.length > 4 ? 200 : 160, overflow: 'hidden auto' }}>
								{filteredTags.map((value, index) => (
									<div key={`tag-${index}`}>
										<FormControlLabel
											control={
												<Checkbox
													checked={selectedTags.includes(value)}
													value={value}
													onChange={e => {
														const value = e.target.value
														const newSelectedTags = selectedTags.slice()
														if (e.target.checked) {
															newSelectedTags.push(value)
														} else {
															newSelectedTags.splice(
																selectedTags.findIndex(x => x === value),
																1
															)
														}
														setSelectedTags(newSelectedTags)
														arragroCmsCustomControl.OnChange(controlContext.name, { tags: newSelectedTags })
													}}
												/>
											}
											label={value}
										/>
									</div>
								))}
							</div>
						</>
					)}
				</ComponentWrapper>
			</Grid>
		</Grid>
	)
}

export default TagSelector
