import { PropsWithChildren } from 'react'
import { Box, Grid, Theme, Typography, useTheme } from '@mui/material'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'

const root = (theme: Theme) => ({
    maxWidth: theme.breakpoints.values.lg,
    width: '100%',
    margin: '0 auto'
})
  
const Layout = (props: PropsWithChildren<{ title: string }>) => {
    const theme = useTheme()
    const {
        title,
        children
    } = props

    return <Grid container sx={root(theme)}>
      <Grid sx={{ marginTop: 1 }} container>
        <Grid item xs={12} md={6}>
            <Typography sx={{ marginTop: 2 }} variant='h4'>{title}</Typography>
        </Grid>
        <Grid item xs={12} md={6} justifyContent='flex-end'>
          <Box display="flex" justifyContent="flex-end">
            <Link to='/'>
              <img css={css`width: 250px; margin-right: 10px;`} src="/bayleys-logo.svg" />
              <img css={css`width: 70px`} src="https://cdn.arragro.com/public/images/svgs/arragro-logo-green.svg" />
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Grid item sx={{ marginTop: 3, width: '100%' }}>
        {children}
      </Grid>
    </Grid>
}

export default Layout