import { useEffect, useState, createRef, useRef } from 'react'
// import { useForm } from 'react-hook-form'
import { ArragroCmsCustomControl } from '@arragro/arragrocms-customcontrol'
import { ControlContext } from '@arragro/arragrocms-customcontrol/types/global'
import httpUtils from '../utils/httpUtils'
import config from '../config'
import toast from '../toast'
import { Autocomplete, FormControl, FormHelperText, Grid, TextField } from '@mui/material'
import ComponentWrapper from '../ComponentWrapper'

// type TagSelectorForm = {
//     tags: Array<string>
// }

export interface LookupValues {
    value: string,
    label: string
}

const AgentSelector = () => {
    const containerRef = createRef<HTMLDivElement>()
    const [arragroCmsCustomControl, setArragroCmsCustomControl] = useState<ArragroCmsCustomControl | undefined>()
    const [controlContext, setControlContext] = useState<ControlContext | undefined>()
    const [agents, setAgents] = useState<Array<LookupValues>>([])
    const [selectedAgents, setSelectedAgents] = useState<Array<string>>([])
    const [containerBaseHeight, setContainerBaseHeight] = useState(0)
    const [containerHeight, setContainerHeight] = useState(0)
    const [multiSelect, setMultiSelect] = useState(true)
    const [includeUserName, setIncludeUserName] = useState(false)
    const [error, setError] = useState(false)
    const [isTouched, setIsTouched] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)

    // const { register, handleSubmit, control, reset, formState: { errors } } = useForm<TagSelectorForm>({
    //     defaultValues: {
    //         tags: []
    //     }
    // });

    const onInit = (controlContext: ControlContext) => {
        console.log('AgentSelector:Init', controlContext)
        setControlContext(controlContext)
        const data = controlContext.value

        let derivedMultiSelect = true
        if (controlContext.config !== undefined) {
            derivedMultiSelect = controlContext.config.multiSelect ?? true
            setMultiSelect(derivedMultiSelect)
            setIncludeUserName(controlContext.config.includeUserName ?? false)
        }

        if (derivedMultiSelect) {
            setSelectedAgents((data.agents ?? []).map((x: string | { agentCode: string, agentName: string}) => {
                if (x instanceof Object) {
                    return x.agentCode
                } else {
                    return x
                }
            }))
        } else {
            if (data.agent instanceof Object) {
                setSelectedAgents(data.agent === undefined || data.agent === null ? [] : [data.agent.agentCode])
            } else {
                setSelectedAgents(data.agent === undefined || data.agent === null ? [] : [data.agent])
            }
        }
    }

    const onResetData = (controlContext: ControlContext) => {
        setControlContext(controlContext)
        const data = controlContext.value
        setSelectedAgents(data.agents.map((x: string | { agentCode: string, agentName: string}) => {
            if (x instanceof Object) {
                return x.agentCode
            } else {
                return x
            }
        }))
    }

    const onFormSubmit = (controlContext: ControlContext) => {
        console.log('Agent:onFormSubmit', controlContext)
        setFormSubmitted(true)
    }

    useEffect(() => {
        const arragroCmsCustomControl = new ArragroCmsCustomControl(window.name, {
            onInit: (controlContext) => onInit(controlContext),
            onResetData: (controlContext) => onResetData(controlContext),
            onFormSubmit: (content) => onFormSubmit(content)
        })
        setArragroCmsCustomControl(arragroCmsCustomControl)
        arragroCmsCustomControl.Init()
    }, [])

    useEffect(() => {
        if (controlContext !== undefined) {
            httpUtils.getExternal<Array<LookupValues>>('https://cms-api.bayleys.co.nz/api/agents')
              .then((response) => {
                  if (response.ok) {
                    setAgents(response.data)
                  }
                  else toast.error('Something went wrong getting agents.')
              })
        }
    }, [controlContext])

    useEffect(() => {
        if (arragroCmsCustomControl) {
            if (selectedAgents.length === 0 && !multiSelect) {
                arragroCmsCustomControl.OnValidChange(false)
            }
        }
    }, [arragroCmsCustomControl, selectedAgents])

    useEffect(() => {
        // if (agents !== undefined) {
        //     arragroCmsCustomControl?.SetHeight((agents.length > 4 ? 180 : 50) + (agents.length > 4 ? 4 * 42 : agents.length * 40))
        // }
        if (containerRef.current !== null && containerBaseHeight === 0) {
            console.log('AgentSelector:Height', containerRef.current.offsetHeight)
            setContainerBaseHeight(containerRef.current.offsetHeight)
            setContainerHeight(containerRef.current.offsetHeight)
        }
    }, [containerRef])

    useEffect(() => {
        arragroCmsCustomControl?.SetHeight(containerHeight)
    }, [containerHeight])

    useEffect(() => {
        if (!multiSelect) {
            setError(selectedAgents.length === 0)
        }
    }, [formSubmitted])

    useEffect(() => {
        if (containerRef.current !== null) {
            setContainerBaseHeight(containerRef.current.offsetHeight)
            setContainerHeight(containerRef.current.offsetHeight)
        }
    }, [error])

    if (arragroCmsCustomControl === undefined || controlContext === undefined || agents === undefined) return null
    
    return <Grid ref={containerRef} sx={{ width: '98%' }} container>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
            <FormControl error={error} sx={{ width: '100%' }}>
                <Autocomplete
                    disableCloseOnSelect={multiSelect}
                    multiple={multiSelect}
                    options={agents}
                    renderOption={(props, option) => <li {...props} key={option.value}>{option.label}</li>}
                    getOptionLabel={(option) => option.label}
                    value={multiSelect ? 
                        agents.filter(x => selectedAgents.findIndex(y => y === x.value) >= 0) :
                        agents.find(x => selectedAgents.findIndex(y => y === x.value) >= 0) ?? null}
                    onChange={(_e, value) => {
                        setIsTouched(true)
                        if (Array.isArray(value)) {
                            const selectedAgents = value !== null ? value : []
                            const sortedSelectedAgents = selectedAgents.sort((a, b) => a.label.split(' ')[0].localeCompare(b.label.split(' ')[0]))

                            let newSelectedAgents = new Array<any>()
                            if (!includeUserName) {
                                newSelectedAgents = sortedSelectedAgents.map(x => x.value)
                                setSelectedAgents(newSelectedAgents)
                            } else {
                                newSelectedAgents = sortedSelectedAgents.map(x => ({ agentCode: x.value, agentName: x.label }))
                                setSelectedAgents(newSelectedAgents.map(x => x.agentCode))
                            }
                            if (newSelectedAgents.length === 0) {
                                arragroCmsCustomControl.OnChange(controlContext.name, { agents: newSelectedAgents }, false)
                            } else {
                                arragroCmsCustomControl.OnChange(controlContext.name, { agents: newSelectedAgents }, true)
                            }
                        } else {
                            const selectedAgents = value !== null ? [value] : []
                            setSelectedAgents(selectedAgents.map(x => x.value))
                            if (selectedAgents.length === 0) {
                                arragroCmsCustomControl.OnChange(controlContext.name, {}, false)
                            } else {
                                if (!includeUserName) {
                                    arragroCmsCustomControl.OnChange(controlContext.name, { agent: selectedAgents.length > 0 ? selectedAgents.map(x => x.value)[0] : null }, true)
                                } else {
                                    arragroCmsCustomControl.OnChange(controlContext.name, { agent: selectedAgents.length > 0 ? selectedAgents.map(x => ({ agentCode: x.value, agentName: x.label }))[0] : null }, true)
                                }
                            }
                            setError(selectedAgents.length === 0)
                        }
                    }}
                    sx={{
                        "& ul": { 
                            height: '180px',
                            maxHeight: '180px'
                        }
                    }}
                    ListboxProps={
                        {
                            style:{
                                height: '180px',
                                maxHeight: '180px'
                            }
                        }
                    }
                    onOpen={() => {
                        setContainerHeight(containerBaseHeight + 180)
                    }}
                    onClose={() => {
                        if (containerRef.current) {
                            setContainerBaseHeight(containerRef.current.offsetHeight)
                            setContainerHeight(containerRef.current.offsetHeight)
                        } else {
                            setContainerHeight(containerBaseHeight)
                        }
                        console.log('AgentSelector:OnCloseHeight', containerRef.current?.offsetHeight)
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={multiSelect ? 'Select Agents' : 'Select Agent'}
                            error={error && (isTouched || formSubmitted)}
                        />}
                />
                {error &&
                    <FormHelperText id="component-error-text">You must select an agent</FormHelperText>}
            </FormControl>


        </Grid>
    </Grid>
}

export default AgentSelector