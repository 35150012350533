import { OptionsObject, useSnackbar, VariantType, ProviderContext } from 'notistack'
import React from 'react'

interface IProps {
	setUseSnackbarRef: (showSnackbar: ProviderContext) => void
}

const InnerSnackbarUtilsConfigurator: React.FC<IProps> = (props: IProps) => {
	props.setUseSnackbarRef(useSnackbar())
	return null
}

let useSnackbarRef: ProviderContext
const setUseSnackbarRef = (useSnackbarRefProp: ProviderContext) => {
	useSnackbarRef = useSnackbarRefProp
}

export const SnackbarUtilsConfigurator = () => {
	return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
}

export default {
	success(msg: string | JSX.Element, options?: OptionsObject) {
		this.toast(msg, 'success', options)
	},
	warning(msg: string | JSX.Element, options?: OptionsObject) {
		this.toast(msg, 'warning', options)
	},
	info(msg: string | JSX.Element, options?: OptionsObject) {
		this.toast(msg, 'info', options)
	},
	error(msg: string | JSX.Element, options?: OptionsObject) {
		this.toast(msg, 'error', options)
	},
	toast(msg: string | JSX.Element, variant: VariantType = 'default', options?: OptionsObject) {
		useSnackbarRef.enqueueSnackbar(msg, { ...options, variant })
	},
}
