import * as React from 'react'
import { AuthProvider, useAuth, User } from 'oidc-react'
import { Outlet } from 'react-router-dom';
import { IUserSite } from './interfaces'
import Config, { UserManager } from './config'

export type LoggedInUserContextType = {
    user: User | null,
    getUserSites: (user: User | null) => Array<IUserSite>
}

const defaultLoggedInUserContext = {
    user: null,
    getUserSites: (user: User | null) => {
        if (user === null) return []
        if (user.profile.sites === null) return []
        if (user.profile.sites === undefined) return []
        return user.profile.sites as Array<IUserSite>
    }
}

const LoggedInUserContext = React.createContext<LoggedInUserContextType>(defaultLoggedInUserContext)

export const useLoggedInUserContext = () => React.useContext(LoggedInUserContext)

const Authy = () => {
    const auth = useAuth();
    const [user, setUser] = React.useState<User | null>(null)

    React.useEffect(() => {
        if (user !== null) {
            var roles = (user.profile as any).role as Array<string> | string
            if (Array.isArray(roles)) {
                if (roles.findIndex(x => x === 'Administrator' || x === 'Site Administrator') < 0) {
                    window.location.href = '/access-denied'
                }
            } else if (!(roles === 'Administrator' || roles === 'Site Administrator')) {
                window.location.href = '/access-denied'
            }
        }
    }, [user])

    auth.userManager.events.addUserLoaded((user) => {
        setUser(user)
    })
    auth.userManager.events.addUserSignedIn(() => {
        auth.userManager.getUser().then(u => {
            setUser(u)
        })
        .catch(error => console.error('geaddUserSignedIntUserError', error))
    })

    React.useEffect(() => {
        auth.userManager.getUser().then(user => {
            if (user !== null) {
                setUser(user)
            } else if (!auth.isLoading) {
                auth.userManager.signinRedirect().then((c) => console.log(c))
            }
        })
            .catch(error => console.error('getUserError', error))
    }, [])

    if (!(/^\/\?code=(.+)/.test(window.location.pathname + window.location.search)) &&
        window.location.pathname !== '/logged-out') {
        sessionStorage.setItem('redirectOnLogin', window.location.pathname + window.location.search)
        console.debug('EnsureLoggedInContainer: set redirectOnLogin', window.location.pathname + window.location.search)
    }

    if (user !== null) {
        return <LoggedInUserContext.Provider value={{ ...defaultLoggedInUserContext, user }}>
            <Outlet />
        </LoggedInUserContext.Provider>
    } else {
        return null
    }
}

const EnsureLoggedInContainer = (props: React.PropsWithChildren<any>) => {
    return <AuthProvider userManager={UserManager} {...Config.oidcConfig}>
        <Authy />
    </AuthProvider>
}

export default EnsureLoggedInContainer
