import { IFetchResult } from '../interfaces'
import Config, { UserManager } from '../config'
import toast from '../toast'

export class HttpUtils {

    showDefaultFetchError = true

    process500<T>(response: Response, text: string, resolve: (value: IFetchResult<T> | PromiseLike<IFetchResult<T>>) => void, reject: (reason?: any) => void): void {

        try {
            const data = JSON.parse(text) as T
            resolve({
                status: response.status,
                ok: response.ok,
                data: data
            })
        } catch {
            if (response.status === 401) {
                fetch('/', {
                    credentials: 'same-origin'
                })
                reject({
                    ok: false,
                    status: response.status,
                    data: {
                        errorMessage: 'Your session expired, please login again',
                        configurationData: undefined,
                        contentData: undefined,
                        errorMessages: ['Your session expired, please login again'],
                        errors: {},
                        rulesExceptionListContainers: []
                    }
                })
            } else {
                reject({
                    ok: false,
                    status: response.status,
                    data: {
                        errorMessage: 'Something has gone wrong on the server!',
                        configurationData: undefined,
                        contentData: undefined,
                        errorMessages: ['Something has gone wrong on the server!'],
                        errors: {},
                        rulesExceptionListContainers: []
                    }
                })
            }
        }
    }

    parseJSON<T>(response: Response, ignore401 = false): Promise<IFetchResult<T>> {
        return new Promise((resolve, reject) => {
            if (response.status === 401) {
                response.text()
                    .then(async (text) => {
                        if (ignore401) {
                            this.process500(response, text, resolve, reject)
                        } else {
                            if (text.length > 0) {
                                const data = JSON.parse(text)
                                toast.error(data.message)
                            }
                            console.log('401: signinRedirect')
                            const location = window.location
                            await UserManager.removeUser()
                            sessionStorage.setItem('redirectOnLogin', location.pathname + location.search)
                            await UserManager.signinRedirect()
                            // if (location.pathname !== '/account/logoutFromExpired') {
                            //     history.push(`/account/logoutFromExpired?returnUrl=${encodeURIComponent(location.pathname + location.search)}&logout=true`)
                            // }
                        }
                        resolve({
                            status: response.status,
                            ok: response.ok,
                            data: {} as T
                        })
                    })
                    .catch(err => {
                        console.log(err)
                    })
                return
            } else if (response.status === 403) {
                window.location.href = '/access-denied'
            }
            //  else if (response.status === 404) {
            //     history.replace('/Four04')
            // }

            response.text()
                .then((text) => {
                    if (text.length > 0) {
                        if (!response.ok) {
                            switch (response.status) {
                                case 406:
                                case 422:
                                case 500: {
                                    this.process500(response, text, resolve, reject)
                                    break
                                }
                                case 410: {
                                    const data = JSON.parse(text)
                                    window.location.href = `/maintenance?returnUrl=${encodeURIComponent(location.pathname + location.search)}&requestedTimeStamp=${data.RequestedTimestamp}&requestedBy=${data.Requester}`
                                    break
                                }
                                default: {
                                    toast.error('Something bad has happened, view console log.')
                                    console.error({ response, text })
                                    break
                                }
                            }
                        } else {
                            resolve({
                                status: response.status,
                                ok: response.ok,
                                data: JSON.parse(text) as T
                            })
                        }
                    } else {
                        resolve({
                            status: response.status,
                            ok: response.ok,
                            data: {} as T
                        })
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    get<T>(url: string): Promise<IFetchResult<T>> {
        return this.futchGet(`${Config.apiConfigUrl}${url}`)
    }

    getExternal<T>(url: string): Promise<IFetchResult<T>> {
        return this.futchGet(`${url}`)
    }

    post<T, R>(url: string, postData: T, ignore401 = false): Promise<IFetchResult<R>> {
        return this.futch<T, R>(`${Config.apiConfigUrl}${url}`, 'POST', postData, ignore401)
    }

    postExternal<T, R>(url: string, postData: T, ignore401 = false): Promise<IFetchResult<R>> {
        return this.futch<T, R>(url, 'POST', postData, ignore401)
    }

    put<T, R>(url: string, postData: T): Promise<IFetchResult<R>> {
        return this.futch<T, R>(`${Config.apiConfigUrl}${url}`, 'PUT', postData)
    }

    putExternal<T, R>(url: string, postData: T): Promise<IFetchResult<R>> {
        return this.futch<T, R>(url, 'PUT', postData)
    }

    delete<T, R>(url: string, postData: T): Promise<IFetchResult<R>> {
        return this.futch<T, R>(`${Config.apiConfigUrl}${url}`, 'DELETE', postData)
    }

    deleteExternal<T, R>(url: string, postData: T): Promise<IFetchResult<R>> {
        return this.futch<T, R>(`${Config.apiConfigUrl}${url}`, 'DELETE', postData)
    }

    postXhr = async (
        url: string,
        opts: { method: string, headers: { [key: string]: string }, body?: XMLHttpRequestBodyInit | Document | null | undefined },
        onLoad: (this: XMLHttpRequest, event: ProgressEvent<EventTarget>) => any,
        onError: (this: XMLHttpRequest, event: ProgressEvent<EventTarget>) => any,
        onProgress: (this: XMLHttpRequest, event: ProgressEvent<EventTarget>) => any,
        onComplete: (this: XMLHttpRequest, event: Event) => any): Promise<void> => {
        const user = await UserManager.getUser()
        const xhr = new XMLHttpRequest()
        xhr.open(opts.method || 'get', url)
        for (const k in opts.headers || {}) {
            xhr.setRequestHeader(k, opts.headers[k])
        }
        xhr.onload = onLoad
        xhr.onerror = onError
        xhr.onreadystatechange = onComplete
        xhr.setRequestHeader('Authorization', `bearer ${user === null ? '' : user.access_token}`)
        if (xhr.upload && onProgress !== undefined) {
            xhr.upload.onprogress = onProgress // event.loaded / event.total * 100  //event.lengthComputable
        }
        xhr.send(opts.body)
    }

    private async futchGet<T>(url: string): Promise<IFetchResult<T>> {
        const user = await UserManager.getUser()

        try {
            const response = await fetch(`${url}`, {
                headers: new Headers({
                    'Authorization': `bearer ${user === null ? '' : user.access_token}`
                })
            })
            return await this.parseJSON<T>(response)
        } catch (error: any) {
            if (!user?.expired && url !== '/api/user/current' && this.showDefaultFetchError) {
                if (error.data && error.data.errorMessage) {
                    toast.error(`${error.data.errorMessage}`)
                } else if (error.message) {
                    toast.error(`${error.message}`)
                }
            }
            throw error
        }
    }

    private async futch<T, R>(url: string, verb: string, postData: T, ignore401 = false): Promise<IFetchResult<R>> {
        const user = await UserManager.getUser()

        try {
            const response = await fetch(url, {
                credentials: 'same-origin',
                method: verb,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${user === null ? '' : user.access_token}`
                },
                body: JSON.stringify(postData)
            })
            return await this.parseJSON<R>(response, ignore401)
        } catch (error: any) {
            if (!user?.expired && this.showDefaultFetchError) {
                if (error.data && error.data.errorMessage) {
                    toast.error(`${error.data.errorMessage}`)
                } else if (error.message) {
                    toast.error(`${error.message}`)
                    throw ({
                        ok: false,
                        status: 500,
                        data: {
                            errorMessages: new Array<string>(error.message)
                        }
                    } as any)
                }
            }

            throw error
        }
    }
}

const httpUtils = new HttpUtils()
export default httpUtils
